h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0;
}

.head {
  padding: 12px 20px;
  background: #fff;
  display: flex;
  gap: 12px;
  button {
    padding: 0;
    height: auto;
    min-width: auto;
  }
}

.foot {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0px -4px 40px 0px rgba(0, 0, 0, 0.1);
  padding: 16px 20px 8px 20px;
  z-index: 12;
  gap: 16px;
  .btn_trans {
    width: 100%;
    border-radius: 8px;
    background: transparent;
    height: 44px;
    padding: 1px 16px;
    color: #c8172f;
    font-size: 14px;
    border: 1px solid #c8172f;
    font-weight: 500;
    line-height: 20px;
    text-transform: unset;
    &:hover {
      background: transparent;
      color: #c8172f;
    }
    &.Mui-disabled {
      background: #7d7e7f;
      box-shadow: 0px 4px 40px 0px rgba(185, 0, 34, 0.1);
    }
  }
  .btn_red {
    width: 100%;
    border-radius: 8px;
    background: #c8172f;
    height: 44px;
    padding: 1px 16px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.14px;
    &:hover {
      background: #c8172f;
      color: #fff;
    }
    &.Mui-disabled {
      background: #7d7e7f;
      box-shadow: 0px 4px 40px 0px rgba(185, 0, 34, 0.1);
    }
  }
  p {
    color: #6b6b6b;
    margin-top: 8px;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #6b6b6b;
    font-size: 10px;
    text-align: center;
  }
}

.Video {
  .btn_red {
    width: 100%;
    border-radius: 8px;
    height: 44px;
    padding: 1px 16px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.14px;
  }
}

