
.cardHead12{
    .MuiTypography-body2{
        color: #696A6B;
        letter-spacing: 24px;
        span{
            color: #282829;
            font-weight: 600;
        }
    }
}
