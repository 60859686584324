
.taskTabs{
    // .MuiTabs-scroller{
    //     margin-bottom: 16px !important;
    // }
    .MuiButtonBase-root{
        width: 33.3%;
        color: #494A4B;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        text-transform: capitalize;
        &.Mui-selected{
            color: #000;
            font-weight: 600;
        }
    }
    .MuiTabs-indicator{
        background-color: #C8172F;
    }
}
