
.cardHead{
    .MuiTypography-body2{
        color: #696A6B;
        letter-spacing: 0.14px;
        span{
            color: #282829;
            font-weight: 700;
        }
    }
}