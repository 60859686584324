:root{
    --red: #B90022;
    --red1: #C8172F;
    --red2: #D52035;
    --red3: #E72B3B;
    --red4: #F7363C;
    --red5: #F14B56;
    --red6: #E56F77;
    --red7: #EF979D;
    --red8: #FECCD4;
    --red9: #FFEBEF;


    --white: #fff;

    --black: #282829;
    --black1: #494A4B;
    --black2: #696A6B;
    --grey: #7D7E7F;
    --grey1: #A7A8A9;
    --grey2: #C5C6C7;
    --grey3: #E7E8E9;
    --grey4: #F9FBFC;
    --grey5: #F5F6F7;
    --grey6: #F1F2F3;

    --fw-300: 300 !important;
    --fw-400: 400 !important;
    --fw-500: 500 !important;
    --fw-600: 600 !important;
    --fw-700: 700 !important;
}