.steps{
    padding-bottom: 100px;
}
.questionCard{
    border-radius: 16px;
    background: #FFF;
    text-align: left;
    margin: 26px 20px 0 20px;
    .cardContent{
        padding: 16px;
        h5{
            line-height: 26px;
            color: #282829;
            margin-bottom: 20px;
        }
    }
}
.steps{
    margin: 0 20px;
    .img-fluid{
        width: 100%;
        height: auto;
    }
}
.stepperForm{
    border-radius: 16px;
    background: #FFF;
    text-align: left;
    width: 100%;
    .custonRadio1{
        padding: 16px;
        .MuiFormControl-root{
            width: 100%;
        }
        .MuiFormLabel-root{
            color: #282829 !important;
            font-size: 20px;
            font-weight: 600;
            line-height: 130%; 
            letter-spacing: 0.2px;
            text-align: left;
            margin-bottom: 20px;
        }
        label{
            width: 100%;
            margin: 0 0 12px 0;
            height: 24px;
            padding: 0 12px;
            flex-direction: initial;
            &:last-child{
                margin-bottom: 0;
            }
            .MuiButtonBase-root{
                position: absolute;
                right: 8px;
                color: #C5C6C7;
                padding: 0;
                // &.Mui-checked{
                //     color: #C8172F !important;
                // }
            }
            .MuiTypography-root{
                font-size: 16px;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.16px;
            }
        }
    }
    .custonRatings{
        padding: 16px 16px 46px 16px;
        .MuiFormControl-root{
            width: 100%;
        }
        .MuiFormLabel-root{
            color: #282829 !important;
            font-size: 20px;
            font-weight: 600;
            line-height: 130%; 
            letter-spacing: 0.2px;
            text-align: left;
            margin-bottom: 20px;
        }

        label{
            background: #FFF;
            width: 100%;
            margin: 0 0 12px 0;
            height: 15px;
            flex-direction: row;
            &:last-child{
                margin-bottom: 0;
                display: none;
            };
            .MuiButtonBase-root{
                position: absolute;
                right: 8px;
                color: #C5C6C7;
                padding: 0;
                // &.Mui-checked{
                //     color: #C8172F !important;
                // }
            }
            .MuiTypography-root{
                font-size: 16px;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.16px;
            }
        }
    }
}

.stepsCount{
    padding: 0 !important;
    .MuiSvgIcon-root{
        border-radius: 30px;
        border: 1px solid #C5C6C7;
        background: #F5F6F7;
        color: transparent;
        &.Mui-active{
            border-radius: 30px;
            border: 1px solid #C8172F;
            background: #FFEBEF;
            color: transparent;
            .MuiStepIcon-text{
                fill: #C8172F;
            }
        }
        &.Mui-completed{
            color: #46C65A;
        }
        .MuiStepIcon-text{
            fill: #7D7E7F;
            font-size: 12px;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.12px;
        }
    }
    
    .MuiStepLabel-labelContainer{
        .MuiStepLabel-label{
            color: #7D7E7F;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.12px;
            margin-top: 4px;
            .points_count{
                display: block;
                color: #7D7E7F;
                text-align: center;
                font-size: 12px;
                font-weight: 600;
                line-height: 18px; 
                letter-spacing: 0.12px;
                .error{
                    color: #C8172F;
                }
            }
            .error{
                color: #C8172F;
            }
        }
    }
    
    .MuiStepConnector-root{
        top: 11px;
        left: calc(-50% + 10px);
        right: calc(50% + 10px);
        .MuiStepConnector-line{
            border-color: #FFEBEF;
            border-top-width: 4px;
        }
    }
}


.hint{
    border-radius: 6px;
    background: rgba(245, 230, 150, 0.40);
    display: flex;
    padding: 8px 10px;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.chanceLimit{
    border-radius: 8px;
    background: #FFF;
    padding: 10px;
    justify-content: space-between;
    display: flex;
    margin: 16px 0;
    align-items: center;
    .MuiTypography-caption{
        color: #282829;
        font-weight: 500;
    }
    .count{
        display: flex;
        gap: 4px;
        align-items: center;
        .MuiTypography-body2{
            color: #282829;
            font-weight: 500;
        }
    }
}

.holdOn{
    .closeBtn{
        position: absolute;
        top: -55px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 30px;
        border: 1px solid var(--60, #C5C6C7);
        background: #F5F6F7;
        &:hover{
            background: #F5F6F7;
        }
    }
    .modalContent{
        text-align: center;
        .MuiTypography-body2{
            color: #7D7E7F;
            line-height: 19.6px;
            max-width: 291px;
            margin: 8px auto 0 auto;
        }
        .modal_cta{
            display: flex;
            gap: 16px;
            margin-top: 32px;
            button{
                max-width: 159px;
                width: 100%;
                height: 40px;
                padding: 1px 16px;
                text-align: center;
                font-size: 14px;
                font-weight: 700;
                line-height: 21px; 
                letter-spacing: 0.14px;
                border-radius: 8px;
            }
            .red_btn{
                color: #FFF;
                border: 0;
                background:#C8172F;
            }
            .white_btn{
                color: #C8172F;
                border: 1px solid  #C8172F;
            }
        }
    }
}

.correctAnswer{
    .closeBtn{
        position: absolute;
        top: -55px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 30px;
        border: 1px solid var(--60, #C5C6C7);
        background: #F5F6F7;
        &:hover{
            background: #F5F6F7;
        }
    }
    .modalContent{
        text-align: center;
        .MuiTypography-caption1{
            color: #7D7E7F;
            line-height: 16.8px;
            margin-top: 20px;
            display: inline-block;
        }
        .MuiTypography-body1{
            color: #1D2939;
            font-weight: 600;
            line-height: 140%;
            display: inline-block;
            margin-bottom: 16px;
        }
        .answerBox{
            border-radius: 10px;
            border: 1px solid #3BA755;
            background: #EBF7E6;
            display: flex;
            height: 48px;
            padding: 10px 12px;
            justify-content: space-between;
            align-items: center;
            .MuiTypography-body1{
                margin-bottom: 0;
            }
        }
        .modal_cta{
            margin-top: 20px;
            .red_btn{
                max-width: 100%;
            }
        }
    }
}

.wrongAnswer{
    top: 40px !important;
    .MuiPaper-root{
        border-radius: 9px;
        background: #FFF;
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.10);
        height: 40px;
        padding: 0px 10px;
        gap: 6px;
    }
    .MuiAlert-message{
        color: #282829;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px; 
        letter-spacing: 0.12px;
        padding: 0;
        align-self: center;
    }
}
