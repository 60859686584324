body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.vds-slider-track-fill {
  background-color: #c8172f !important;
  pointer-events: none !important;
}

.vds-mute-button,
.vds-google-cast-button,
.vds-rotate-icon,
.vds-airplay-button {
  display: none !important;
  cursor: unset !important;
}
[data-media-player][data-layout="video"]:not([data-fullscreen]) {
  border-radius: unset !important ;
  --media-width: 100% !important;
}

.videoPlayerrrrrr {
  --media-brand: #ffa737;
  --media-focus-ring-color: #4e9cf6;
  --media-focus-ring: 0 0 0 3px var(--media-focus-ring-color);

  --media-tooltip-y-offset: 30px;
  --media-menu-y-offset: 30px;
  --media-border-radius: 17px;

  aspect-ratio: 9 /6;
  border-radius: var(--media-border-radius);
  color: #f5f5f5;
  contain: layout;
  border-radius: 17px;
  background-color: #000;
  overflow: hidden;
  width: 100%;
}
