.title_text {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  max-width: 212px;
  position: relative;
  text-align: start;
  .exc_liner {
    background: linear-gradient(90deg, #fecb5b 18.17%, #fc7b03 59.34%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
}

.title {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.32px;
  text-align: start;
}
.sub_title {
  color: #c5c6c7;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: start;
  margin-bottom: 20px;
}
.cost_off {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: start;
}
.shop_offer {
  color: #e7e8e9;
  text-align: start;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.Unclock_pnts {
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.buy_marlo {
  color: var(--10, #282829);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 4px;
}
.contact {
  color: #787878;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.06px;
  margin-bottom: 16px;
}
.call_us {
  color: #c8172f;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
}
