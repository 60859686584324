.active_tasks {
  color: #282829;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.32px;
  text-align: start;
  margin-bottom: 12px;
}
.view_all_tasks {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.btn_red {
  width: 100%;
  border-radius: 8px;
  height: 44px;
  padding: 1px 16px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.14px;
}
.card_div {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
  gap: 12px;
  padding: 12px 10px;
  margin-bottom: 12px;
  width: 100%;
  max-width: 335px;
  .task_name {
    color: #282829;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.32px;
    text-align: start;
    max-width: 212px;
  }
  .inner_card {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    .cx_200 {
      color: #282829;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: -0.24px;
    }
  }
}
