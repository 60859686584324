.notecoin{
    border-radius: 16px;
    border: 1px solid rgba(250, 212, 102, 0.00);
    background: linear-gradient(110deg, rgba(255, 248, 187, 0.00) 38.52%, rgba(220, 220, 106, 0.32) 100%), #FFF;
    margin: 12px 20px 90px;
    padding: 18px 0 18px 16px;
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    .content{
        text-align: start;
        .caption1{font-weight: 600;color: #7D7E7F;margin-bottom: 2px;}
        .body1{font-weight: 600;line-height: 22px;max-width: 183px;font-size: 14px;}
    }
    .coinn{
        position: absolute;
        right: -20px;
        bottom: -37px;
    }
}