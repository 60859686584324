.transactions-date {
  padding: 20px;
  display: flex;
  align-items: center;
  text-align: start;
  .transactions-div {
    .transactions-text {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #282829;
    }
    .results {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #7d7d7d;
    }
  }
}
.border-line {
  position: relative;
  border: 1px solid;
  border-image-source: linear-gradient(
    269.01deg,
    rgba(255, 255, 255, 0) 2.79%,
    rgba(252, 139, 22, 0) 21.89%,
    #fc8b16 50.54%,
    rgba(252, 139, 22, 0) 79.2%,
    rgba(255, 255, 255, 0) 98.3%
  );
  border-image-slice: 1;
}
