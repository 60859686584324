.activities {
  background-color: #fff;
  .verify {
    background-color: #f5f6f7;
    padding: 9px;
    border-radius: 50%;
  }
  .ss {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #334155;
    text-align: start;
    span {
      font-weight: 600 !important;
      color: #000;
    }
  }
}

