.know_our {
  color: #282829;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.32px;
}
.new_task {
  color: #696a6b;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: start;
}
.complete_to_earn {
  color: #282829;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.start_the_task {
  border-radius: 8px;
  text-decoration: none;
  background: #c8172f;
  box-shadow: 0px 0px 15px 0px rgba(251, 188, 5, 0.6);
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 7px 12px;
}
.days_left {
  color: #282829;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.06px;
}
