.stepper-container {
  display: flex;
  align-items: center;
  justify-content: center;
  .white-circle {
    background-color: #fff;
    width: 9px;
    height: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0px 0px 3.987px rgba(0, 0, 0, 0.18));
    border-radius: 50%;
    .step-circle {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #e0e0e0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #666;
      position: relative;

      &.completed {
        background-color: #3ba755;
        color: white;
      }
    }
  }
  .connector {
    flex: 1;
    height: 4px;
    background-color: #e0e0e0;
    position: relative;
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &.completed {
      background-color: #3ba755;
    }
  }
}
