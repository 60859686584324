.profile{
    border-radius: 0px 0px 16px 16px;
    border-bottom: 1px solid #E8E8B9;
    background: linear-gradient(180deg, rgba(255, 248, 187, 0.00) 21.44%, rgba(220, 220, 106, 0.32) 106.48%), #FFF;
    padding: 16px 32px 24px;
    .letter{
      background-image: url(../../assests/images/border_gold.svg);height: 70px;width: 70px;background-repeat: no-repeat;display: flex;
      align-items: center;
      justify-content: center;
      background-size: 100%;
      margin: 0 auto;
      h2{
        background: linear-gradient(219deg, #F1B74B 17.57%, #8B6A2B 89.75%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .user_name{
      font-size: 22px;
    }
    .grey-flex{
      display: flex;
      border-radius: 16px;
      border: 1px solid #FAD466;
      background: #FFF;
      padding: 12px 16px;
      .card{
          &:first-child{border-right: 2px solid #E8E8E8;    padding-right: 20px;margin-right: 20px;}
          display: flex;
          gap: 8px;
          text-align: start;
          .caption1{
              line-height: 18px;
              color: #717171;
              span{ font-weight: 700; color: #000;font-size: 14px;}
          }
      }
  }
}

.informations{
  img{
    padding: 6px;
    border-radius: 5.333px;
    background: rgba(255, 235, 239, 0.50);
  }
  .informations-1{
    &:last-child{border: 0 !important;}
  }
  .secondary{
    img{background-color: transparent !important;padding: 0;}
  }
}