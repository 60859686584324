.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* video-Player styles */
.video-react-play-control{display: none !important;}
.video-react .video-react-slider, .video-react .video-react-load-progress div{background-color: lightgrey !important;}
.video-react .video-react-progress-holder{border-radius: 87px !important;}
.video-react .video-react-control-bar{background-color: #FFF !important;padding-left: 10px;flex-direction: row-reverse;align-items: center !important;}
.video-react .video-react-volume-menu-button{display: none !important;}
.video-react .video-react-time-control{font-size:10px !important;font-weight: 600 !important;padding: 0 3px !important;}
.video-react .video-react-big-play-button{top: 50% !important;left: 50% !important;transform: translate(-50%, -50%);border-color: transparent !important;background-color: transparent !important;background-color: rgba(0, 0, 0, 0.30) !important;width: 45px !important;height: 45px !important;border-radius: 50% ! IMPORTANT;}
.video-react-duration-display, .video-react-time-divider{color: #000;}
.video-react-progress-control.video-react-control{display: none !important;} 
.video-react .video-react-progress-control:hover .video-react-progress-holder{font-size: 0.9em !important;}
.video-react-current-time-display, .video-react .video-react-control:before{color: #000;opacity: 0.7;}
.video-react-fullscreen-control{position: absolute !important;right: 0;}
.video-react-control-bar .MuiSlider-root{margin:0 40px 0px 8px;}
.custom_slider .MuiSlider-thumb::before{background:#FFF;border: 2px solid rgba(200, 23, 47, 1);border-radius: 50%;width: 12px;height: 12px;}
.custom_slider .MuiSlider-thumb{background:transparent ;}
.custom_slider .MuiSlider-track{background: rgba(200, 23, 47, 1);border: unset !important;}
/* video-Player styles */