.BrandSlick-class {
  margin: 20px 0 16px;
  .slick-dots {
    bottom: -10px;
    li {
      width: auto;
      height: auto;
      button {
        background-color: #cfd0d1;
        opacity: 1;
        width: 6px;
        height: 6px;
        padding: 0;
        border-radius: 50%;
        &:before {
          display: none;
        }
      }
    }
    li.slick-active {
      button {
        width: 18px;
        height: 6px;
        background-color: #000000;
        padding: 0;
        border-radius: 15px;
        opacity: 1;
        &:before {
          display: none;
        }
      }
    }
  }
}
