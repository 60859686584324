.congrats {
  border-radius: 0px 0px 16px 16px;
  // background: linear-gradient(180deg, rgba(200, 23, 47, 0.00)21.38%, rgba(200, 23, 47, 0.32)100%), #FFF;
  padding: 28px 20px 24px;
  .pts {
    font-size: 40px;
    font-weight: 600;
    // color: #46C65A;
    margin: 10px 0;
  }
  .totalpoint {
    font-weight: 500;
    color: #000;
    margin-top: 16px;
  }
  .totalpoint2 {
    font-weight: 500;
    color: #7d7e7f;
    margin: 20px 0 12px;
    font-size: 14px;
  }
  .status-type {
    color: #e4a201;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
  }
  .message {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.06px;
    color: #696a6b;
    max-width: 270px;
    margin: 4px auto 0;
  }
  .grey-flex {
    display: flex;
    border-radius: 16px;
    border: 1px solid #e8e8e8;
    background-color: #f5f6f7;
    padding: 12px 16px;
    .card {
      &:first-child {
        border-right: 2px solid #e8e8e8;
        padding-right: 20px;
        margin-right: 20px;
      }
      display: flex;
      gap: 8px;
      text-align: start;
      .caption1 {
        line-height: 18px;
        color: #717171;
        span {
          font-weight: 700;
          color: #000;
          font-size: 14px;
          padding: 3px;
        }
      }
    }
  }
}

.vouchers {
  padding: 0 20px;
  margin: 16px 0 0px;
  .voucher_head {
    font-weight: 500;
    color: #3b3b3b;
    text-align: start;
    margin-bottom: 16px;
  }
  .pricecard {
    background-image: url(../../assests/images/white_bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // background: #FFF;
    padding: 10px 0 10px;
    border-radius: 16px;
    position: relative;
    margin-bottom: 18px;
    .caption1 {
      font-weight: 700;
      padding-top: 8px;
      color: #696a6b;
    }
    .amzon {
      margin-top: 30px;
    }
    .coin {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f9e3b6;
      width: fit-content;
      padding: 6px 10px;
      border-radius: 16px;
      gap: 4px;
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      white-space: pre;
      .numbers {
        font-weight: 700;
        color: #8c4b02;
      }
    }
    .price {
      border-bottom: 2px dashed #e8e8e8;
      max-width: 135px;
      padding-bottom: 8px;
      margin: 0 auto;
    }
    a {
      &:hover {
        color: #c8172f;
        background-color: transparent;
      }
      padding: 0;
      height: fit-content;
      font-size: 14px;
      font-weight: 700;
      color: #c8172f;
      margin-top: 8px;
    }
  }
}
